import React from "react";
import logo from "../assets/stride-logo.svg";
import twitterIcon from "../assets/icons-svg/twitterIcon.svg";
import linkdlnIcon from "../assets/icons-svg/linkdlinIcon.svg";
import facebookIcon from "../assets/icons-svg/facebookIcon.svg";
import { NavLink } from "react-router-dom";
const Footer = () => {
  return (
    <footer className=" bg-grey-350 tablet:px-12 mobileL:px-8 mobileL:py-8 px-20 py-12 mt-[200px]">
      <div className="flex mobileM:flex-col mobileM:items-start justify-between items-center">
        <img
          src={logo}
          alt="stride-logo"
          className="w-[120px] mobileM:w-[100px] h-[40px]"
        />
        <div className="mobileM:mt-2">
          <h3 className="font-medium text-black-600 mobileL:text-base text-lg">
            Get in touch!
          </h3>
          <a target="#" href="mailto:info@stride.ai">
            <button className="flex items-center gap-1 text-grey-500 mobileL:text-[10px] text-sm hover:text-primary-600">
              <span className="material-icons-outlined text-xl">email</span>
              <span>info@stride.ai</span>
            </button>
          </a>
        </div>
      </div>
      <div className="mobileL:text-sm flex justify-between items-baseline">
        <div className="mt-[28px]  text-grey-500 font-medium">
          <div>
            <h4 className="flex items-center gap-1">
              <span className="material-icons-outlined">place</span>
              USA
            </h4>
            <p className="mt-2 mobileM:mt-0 mobileM:text-[12px]">
              1217 ROMAN POINT DR , NORCROSS GA 30093
            </p>
          </div>
          <div className="mt-10 mobileM:mt-5">
            <h4 className="flex items-center gap-1">
              <span className="material-icons-outlined">place</span>
              India
            </h4>
            <p className="mt-2 mobileM:mt-0 mobileM:text-[12px]">
              #1A Block, 1st floor, CIL Layout Sanjaynagar
            </p>
            <p className="mt-1  mobileM:mt-0 mobileM:text-[12px]">
              Main road Bengaluru - 560004
            </p>
          </div>
        </div>
      </div>
      <ul className="flex mobileM:flex-wrap mobileS:flex-col  mobileM:gap-4 mobileM:text-sm  gap-9 mobileL:mt-[50px] mt-[80px] text-grey-500 font-medium">
        <NavLink
          to="/"
          style={({ isActive }) => ({
            color: isActive ? "#756BEA " : "",
          })}
        >
          <li>
            <button>Home</button>
          </li>
        </NavLink>
        <NavLink
          to="/platform"
          style={({ isActive }) => ({
            color: isActive ? "#756BEA " : "",
          })}
        >
          <li>
            <button>Platform</button>
          </li>
        </NavLink>

        <NavLink
          to="/solutions"
          style={({ isActive }) => ({
            color: isActive ? "#756BEA " : "",
          })}
        >
          <li>
            <button>Solutions</button>
          </li>
        </NavLink>

        <NavLink
          to="/about"
          style={({ isActive }) => ({
            color: isActive ? "#756BEA " : "",
          })}
        >
          <li>
            <button>About</button>
          </li>
        </NavLink>
        <li>
          <button onClick={() => alert("Coming Soon!")}>Careers</button>
        </li>
        {/* <li>
          <button>Help</button>
        </li>
        <li>
          <button>Privacy</button>
        </li> */}
      </ul>
      <div className="items-center border-t border-[#EEEFF3] mt-10 pt-9 flex justify-between">
        <p className="text-[#98A2B3] font-normal mobileM:text-sm mobileS:text-[8px]">
          © 2023 Stride.AI. All rights reserved.
        </p>
        <div className="flex gap-4">
          <button className="hover:shadow-2xl">
            <a href="https://twitter.com/stridetext" target="_blank">
              <img src={twitterIcon} alt="twitter-icon" />
            </a>
          </button>
          <button className="hover:shadow-2xl">
            <a href="https://in.linkedin.com/company/stride.ai" target="_blank">
              <img src={linkdlnIcon} alt="linkdin-icon" />
            </a>
          </button>{" "}
          <button className="hover:shadow-2xl">
            <a href="https://www.facebook.com/StrideText/" target="_blank">
              <img src={facebookIcon} alt="facebook-icon" />
            </a>
          </button>
        </div>
      </div>
    </footer>
  );
};

export { Footer };
