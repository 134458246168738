import React from "react";
import { NavLink } from "react-router-dom";

const MobileMenuModal = ({ showMenu }: { showMenu: boolean }) => {
  return (
    <main
      //   onClick={() => setModal((preVal: any) => !preVal)}
      className={`fixed 
      transition-all	duration-700 	
      ${
        showMenu ? "translate-x-0" : "translate-x-[100%]"
      } inset-0 h-screen w-screen flex justify-end  top-[53px] items-start z-30 `}
    >
      <div
        className="bg-white px-2 w-[40%] mobileL:w-[60%]  h-full overflow-auto  shadow-5xl mt-2"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mt-5">
          <ul className="flex flex-col gap-3   text-grey-500 font-medium">
            <NavLink
              to="/"
              style={({ isActive }) => ({
                color: isActive ? "#756BEA " : "",
              })}
            >
              <li className=" border-b border-grey-400 px-2 py-1">
                <button>Home</button>
              </li>
            </NavLink>
            <NavLink
              to="/platform"
              style={({ isActive }) => ({
                color: isActive ? "#756BEA " : "",
              })}
            >
              <li className=" border-b border-grey-400 px-2 py-1">
                <button>Platform</button>
              </li>
            </NavLink>
            <NavLink
              to="/solutions"
              style={({ isActive }) => ({
                color: isActive ? "#756BEA " : "",
              })}
            >
              <li className=" border-b border-grey-400 px-2 py-1">
                <button>Solutions</button>
              </li>
            </NavLink>
            <NavLink
              to="/about"
              style={({ isActive }) => ({
                color: isActive ? "#756BEA " : "",
              })}
            >
              <li className=" border-b border-grey-400 px-2 py-1">
                <button>About</button>
              </li>
            </NavLink>
            <li
              onClick={() => alert("Coming Soon!")}
              className=" border-b border-grey-400 px-2 py-1"
            >
              <button>Careers</button>
            </li>
            <button className=" bg-primary-600 text-white font-medium py-2 px-3 rounded-lg shadow-xl   ">
              <a
                className=" block"
                target="_blank"
                href="https://docs.google.com/forms/d/e/1FAIpQLSe1J9Xb3TXPL2YEF7W2t2s7QxYI00kRrIYPFLfDtY1O1Bt0OA/viewform"
              >
                Get Demo
              </a>
            </button>
          </ul>
        </div>
      </div>
    </main>
  );
};

export { MobileMenuModal };
