import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/stride-logo.svg";
import { MobileMenuModal } from "./MobileMenuModal";
const Nav = () => {
  const [toggleHamburger, setToggleHamburger] = useState(false);
  return (
    <>
      <nav className="2xl:px-28 overflow-hidden sticky top-0 z-50 bg-white 2xl:text-lg tablet:px-6 tablet:py-2 2xl:py-5 mobileS:px-2 xl:text-base mobileL:px-4 mobileL:py-2 border-b border-grey-300 flex  justify-between items-center py-4 px-10">
        <div className="flex items-center gap-12">
          <NavLink to="/">
            <div className="w-[100px] ">
              <img src={logo} alt="stride-logo" className="w-full" />
            </div>
          </NavLink>
          <ul className="flex gap-8 tablet:hidden  text-grey-500 font-medium">
            <NavLink
              to="/platform"
              style={({ isActive }) => ({
                color: isActive ? "#756BEA " : "",
              })}
            >
              <li className="">
                <button>Platform</button>
              </li>
            </NavLink>
            <NavLink
              to="/solutions"
              style={({ isActive }) => ({
                color: isActive ? "#756BEA " : "",
              })}
            >
              <li className="">
                <button>Solutions</button>
              </li>
            </NavLink>
            <NavLink
              to="/about"
              style={({ isActive }) => ({
                color: isActive ? "#756BEA " : "",
              })}
            >
              <li className="">
                <button>About</button>
              </li>
            </NavLink>
            <li onClick={() => alert("Coming Soon!")}>
              <button>Careers</button>
            </li>
          </ul>
        </div>
        <div className="flex  items-center">
          <button className="bg-primary-600 tablet:hidden mobileM:text-sm mobileL:py-[6px] text-white font-medium py-2 px-3 rounded-lg shadow-xl">
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSe1J9Xb3TXPL2YEF7W2t2s7QxYI00kRrIYPFLfDtY1O1Bt0OA/viewform"
            >
              Get Demo
            </a>
          </button>
          <button
            className="text-xl tablet:flex hidden "
            onClick={() =>
              setToggleHamburger((previousValue) => !previousValue)
            }
          >
            <span className="material-icons-outlined text-[44px] text-black-600">
              menu
            </span>
          </button>
        </div>

        <MobileMenuModal showMenu={toggleHamburger} />
      </nav>
    </>
  );
};

export { Nav };
